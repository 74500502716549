<template>
  <h3 class="bh-text bh-text-left bh-text-bold">Surveys</h3>
  <div class="row m-0 text-center">
    <div class="col-1"></div>
    <div class="col-3">Title</div>
    <div class="col-1">Language</div>
    <div class="col-1">Responses</div>
    <div class="col-1">Status</div>
    <div class="col-2">Created date</div>
    <div class="col-2">Creator</div>
    <div class="col-1"></div>
  </div>
  <div
    class="row bh-item text-center"
    v-for="survey in surveys"
    :key="survey._id"
  >
    <div class="col-1">Photo</div>
    <div class="col-3">
      <p class="bh-text-background-secondary text-left">
        <strong>{{ survey.name }}</strong>
      </p>
    </div>
    <div class="col-1">
      <p class="bh-text-background">
        <strong>{{ survey.language }}</strong>
      </p>
    </div>
    <div class="col-1">
      <p class="bh-text-background">{{ survey.answerCount }}</p>
    </div>
    <div class="col-1">
      <p :class="survey.status + ' bh-text-background'">{{ survey.status }}</p>
    </div>
    <div class="col-2">
      <p class="bh-text-background">{{ FilterDate(survey.creationDate) }}</p>
    </div>
    <div class="col-2">
      <!--<img
        :src="mission.creatorPicture"
        style="width: 50px; border-radius: 100%"
      />&nbsp;-->
      <p class="bh-text-background-secondary text-center">
        <strong>{{ survey.creator }}</strong>
      </p>
    </div>
    <div class="col-1" style="display: flex; align-items: center">
      <router-link :to="'/survey/sumary/' + survey._id">
        <img src="../../assets/img/icons/IconTooltip.png" style="width: 40px" />
      </router-link>
    </div>
  </div>
</template>
<script>
import SurveyService from "@/services/SurveyService.js";
import moment from "moment";

export default {
  data() {
    return {
      surveys: [],
      isLoadingPage: true,
    };
  },
  mounted() {
    this.GetSurvey();
  },
  methods: {
    GetSurvey() {
      return SurveyService.GetSurveys()
        .then(async ({ data }) => {
          this.surveys = data.response;
          console.log(data);
          this.isLoadingPage = false;
        })
        .catch((e) => {
          console.log(e.response);
          //window.location.href = "/error";
        });
    },
    SetActiveSurvey(id) {
      return SurveyService.SetActiveSurvey(id)
        .then(async ({ data }) => {
          this.GetSurvey();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    FilterDate(date) {
      if (date) return moment(String(date)).format("MM/DD/YYYY");
    },
    New() {
      window.location.href = "/survey/create";
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/SurveyIndex.css";
</style>